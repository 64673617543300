// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root, .App, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

.app {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body > iframe {
    display: none;
}

html {
    background: #100C1D;
}

body {
    margin: 0;
    text-align: center;
    color: #fff;
    background: #100C1D;
    overflow-x: hidden;
}

.main_content {
    display: flex;
    flex-direction: column;
}

.content_div {
    /*margin-top: 92px;*/
    flex: 1 0 auto;
}

@media (max-width: 1024px) {
    .main_content {
        min-height: 100vh;
    }

    .content_div {
        height: max-content;
        margin-bottom: 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":["html, body, #root, .App, .main_content, .content_div {\n    min-height: 100% !important;\n    height: 100%;\n}\n\n.app {\n    height: 100%;\n}\n\n* {\n    box-sizing: border-box;\n}\n\nbody > iframe {\n    display: none;\n}\n\nhtml {\n    background: #100C1D;\n}\n\nbody {\n    margin: 0;\n    text-align: center;\n    color: #fff;\n    background: #100C1D;\n    overflow-x: hidden;\n}\n\n.main_content {\n    display: flex;\n    flex-direction: column;\n}\n\n.content_div {\n    /*margin-top: 92px;*/\n    flex: 1 0 auto;\n}\n\n@media (max-width: 1024px) {\n    .main_content {\n        min-height: 100vh;\n    }\n\n    .content_div {\n        height: max-content;\n        margin-bottom: 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
