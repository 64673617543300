// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text_field {
    margin: unset;
}

.text_field > div {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: #696969;
    background: #323C49;
    border: 1px solid #424A54;
    border-radius: 5px;
}

.text_field input::placeholder {
    opacity: 1;
}

.text_field input:focus::placeholder {
    color: transparent;
}

.text_field textarea {
    resize: vertical;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextField/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".text_field {\n    margin: unset;\n}\n\n.text_field > div {\n    font-family: 'Source Sans Pro', sans-serif;\n    font-size: 18px;\n    line-height: 23px;\n    color: #696969;\n    background: #323C49;\n    border: 1px solid #424A54;\n    border-radius: 5px;\n}\n\n.text_field input::placeholder {\n    opacity: 1;\n}\n\n.text_field input:focus::placeholder {\n    color: transparent;\n}\n\n.text_field textarea {\n    resize: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
