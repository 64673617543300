// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular_progress {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular_progress.full_screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000070;
    z-index: 9;
}

.circular_progress > div {
    color: #3E8EF7;
}
`, "",{"version":3,"sources":["webpack://./src/components/CircularProgress/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".circular_progress {\n    width: 100%;\n    height: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.circular_progress.full_screen {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: #00000070;\n    z-index: 9;\n}\n\n.circular_progress > div {\n    color: #3E8EF7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
