// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.of_community .snackbar {
    left: 10px;
    transform: translatex(10px);
}

.snackbar > div {
    background: #282525;
}
`, "",{"version":3,"sources":["webpack://./src/components/Snackbar/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".of_community .snackbar {\n    left: 10px;\n    transform: translatex(10px);\n}\n\n.snackbar > div {\n    background: #282525;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
