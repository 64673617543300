// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    border-top: 1px solid #50437B;
    background: #19112D;
    padding: 16px 30px;
    /*position: fixed;*/
    /*bottom: 0;*/
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.footer > div {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 425px) {
    .footer {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/containers/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".footer {\n    border-top: 1px solid #50437B;\n    background: #19112D;\n    padding: 16px 30px;\n    /*position: fixed;*/\n    /*bottom: 0;*/\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-shrink: 0;\n}\n\n.footer > div {\n    color: #FFF;\n    text-align: center;\n    font-family: 'Inter', sans-serif;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n\n@media (max-width: 425px) {\n    .footer {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
